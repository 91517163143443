<template>
  <base-page-layout :title="$route.meta.title">
    <v-card class="mt-3" flat>
      <v-card-title class="mb-6">
        <v-text-field
          v-model="search"
          class="pt-0 mt-0"
          width="300"
          hide-details
          single-line=""
          placeholder="Busca (app_name, workflow, branch, slug)"
          append-icon="search"
          outlined
        />
        <v-spacer />
        <v-btn color="error" class="mr-4" @click="abortDialog = true"><v-icon class="mr-1">mdi-alert-circle</v-icon> Abortar builds ativas</v-btn>
        <v-btn :disabled="refresh" color="primary" class="mr-4" outlined @click="refresh = true"> <v-icon class="mr-1">sync</v-icon> Refresh</v-btn>
      </v-card-title>
      <v-card-text>
        <app-builds-index-table :search="search" :refresh.sync="refresh" />
      </v-card-text>
    </v-card>

    <abort-modal :dialog="abortDialog" @close="closeModal" />
  </base-page-layout>
</template>

<script>
export default {
  name: 'AppBuildLogs',
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    AppBuildsIndexTable: () => import('@/modules/app/views/components/AppBuildsIndexTable.vue'),
    AbortModal: () => import('@/modules/app/views/components/AbortModal.vue')
  },
  data: () => ({
    search: '',
    refresh: false,
    abortDialog: false
  }),
  methods: {
    closeModal() {
      this.abortDialog = false
      this.refresh = true
    }
  }
}
</script>
